<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
    <el-form size="small" ref="inputForm"
             :model="inputForm"
             :rules="rules" label-width="120px">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="任务名称" prop="jobName">
            <el-input v-model.trim="inputForm.jobName"
                      :disabled="inputType"
                      placeholder="请输入任务名称(限20字)"
                      maxlength="20" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="标注模型" prop="modelId">
            <el-select v-model="inputForm.modelId"
                       :disabled="inputType"
                       placeholder="请选择标注模型"
                       style="width: 100%"
                       clearable>
              <el-option
                  v-for="item in modelLabelList"
                  :key="item.id"
                  :label="item.modelName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col v-if="butType == 1" :span="12">
          <el-form-item label="开始时间" prop="startTime">
            <el-date-picker
                style="width: 100%"
                clearable
                :disabled="inputType"
                v-model="inputForm.startTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择开始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col v-if="butType == 1" :span="12">
          <el-form-item label="结束时间" prop="endTime">
            <el-date-picker
                style="width: 100%"
                clearable
                :disabled="inputType"
                v-model="inputForm.endTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="定时启动时间" prop="startUpTime">
            <el-date-picker
                style="width: 100%"
                clearable
                :disabled="inputType"
                v-model="inputForm.startUpTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                type="datetime"
                placeholder="选择定时启动时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="任务描述" prop="remake">
            <el-input v-model.trim="inputForm.remake"
                      :disabled="inputType"
                      placeholder="请输入任务描述(限30字)"
                      maxlength="30" clearable></el-input>
          </el-form-item>
        </el-col>
        <!--                <el-col :span="12">-->
        <!--                    <el-form-item label="模型名称" prop="modelName">-->
        <!--                        <el-input v-model.trim="inputForm.modelName"-->
        <!--                                  :disabled="inputType"-->
        <!--                                  placeholder="请输入模型名称(限20字)"-->
        <!--                                  maxlength="20" clearable></el-input>-->
        <!--                    </el-form-item>-->
        <!--                </el-col>-->
        <!--                <el-col :span="12">-->
        <!--                    <el-form-item label="模型标识内容" prop="modelIdentification">-->
        <!--                        <el-input v-model.trim="inputForm.modelIdentification"-->
        <!--                                  :disabled="inputType"-->
        <!--                                  placeholder="请输入模型标识内容(限50字)"-->
        <!--                                  maxlength="50" clearable></el-input>-->
        <!--                    </el-form-item>-->
        <!--                </el-col>-->
        <!--                <el-col :span="12">-->
        <!--                    <el-form-item label="资源类型" prop="modelIdentification">-->
        <!--                        图片-->
        <!--                    </el-form-item>-->
        <!--                </el-col>-->
        <el-col :span="12" v-show="butType == 1">
          <el-form-item label="启动状态" prop="jobState">
            <el-switch
                :disabled="inputType"
                v-model="inputForm.jobState"
                :active-value="'0'"
                :inactive-value="'1'"
            >
            </el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="标注图片" prop="imgList">
            <resume-upload :disabledBut="inputType" :uploadProgressShow="false" :showSuccessInfo="false" ref="resume-upload" :filtButtonName="filtButtonName"
                           :filtButton="true" :uploadNum="flieNumber" :allowFiles="fileType"
                           @upFileDate="(data)=>getUpData(data)"></resume-upload>
          </el-form-item>

          <el-table
              v-show="inputForm.imgList.length"
              :data="inputForm.imgList"
              size="small"
              ref="multipleTable"
              height="200px"
              class="table"
              :header-cell-style="{background:'#F9DFDF'}"
          >
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="图片" min-width="120px">
              <template slot-scope="scope">
                <div class="flex_a_c">
                  <el-image
                      style="width: 36px; height: 36px"
                      :src="scope.row.inputResource"
                      :fit="'cover'"
                      :preview-src-list="[scope.row.inputResource]"
                  >
                    <div slot="error" class="image-slot">
                      <el-image
                          style="width: 36px; height: 36px"
                          :src="require('@/assets/img/default.png')"
                          :fit="'cover'">
                      </el-image>
                    </div>
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="标注成果" min-width="120px">
              <template slot-scope="scope">
                <div class="flex_a_c" v-show="scope.row.outResource">
                  <el-image
                      style="width: 36px; height: 36px"
                      :src="scope.row.outResource"
                      :fit="'cover'"
                      :preview-src-list="[scope.row.outResource]"
                  >
                  </el-image>
                </div>
                <div class="flex_a_c"
                     v-show="!scope.row.outResource && butType != 0 && toDateSize(new Date(),inputForm.startUpTime)">
                  <el-image
                      style="width: 36px; height: 36px"
                      :src="scope.row.inputResource"
                      :fit="'cover'"
                      :preview-src-list="[scope.row.inputResource]"
                  >
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <!--                        <el-table-column prop="recognition" label="识别效率" show-overflow-tooltip></el-table-column>-->
            <el-table-column v-if="butType != 1" fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button v-if="butType != 1 && !inputType" size="mini" type="text"
                           @click="deleteFile(scope.row,scope.$index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-form>
    <div class="text_center" v-if="butType != 1" style="margin-top: 10px">
      <el-button size="small" @click="handleClose()">取消</el-button>
      <el-button size="small" type="primary" @click="sureData()" v-no-more-click>提交</el-button>
    </div>
    <div class="text_center" v-else style="margin-top: 10px">
      <el-button size="small" @click="handleClose()">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ResumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
  name: "addAutoLabel",
  components: {ResumeUpload},
  data() {
    return {
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
        }
      },
      title: '新增图片自动标注任务',
      dialogVisible: false,
      inputForm: {
        jobName: '',
        modelId: '',
        startUpTime: '',
        remake: '',

        startTime: '',
        endTime: '',
        jobType: '0',
        imgList: [],
      },
      rules: {
        jobName: [
          {required: true, message: '请输入任务名称', trigger: 'blur'},
        ],
        modelId: [
          {required: true, message: '请选择标注模型', trigger: 'change'},
        ],
        // startUpTime: [
        //     {required: true, message: '请选择定时启动时间', trigger: 'change'},
        // ],
        imgList: [
          {type: 'array', required: true, message: '请上传要标注的图片', trigger: 'change'},
        ],
      },
      modelLabelList: [],
      inputType: false,
      butType: '0',//0新增 1查看 2修改

      filtButtonName: '新增图片',
      flieNumber: 10,
      fileType: ['PNG', 'BMP', 'JPG', 'JPEG', 'GIF'],
    }
  },

  mounted() {
    this.$axios(this.api.original.dyygmodelGetDyygModel, {
      modelState: 0,
      keyWord: '',
      current: 1,
      size: 99,
    }, 'post').then(res => {
      if (res.status) {
        this.modelLabelList = res.data.records
      }
    })
  },

  methods: {
    //num 0新增 1查看 2修改
    init(num, row) {
      this.inputType = false
      this.butType = num
      if (num != 0) {
        if (num == 1) {
          this.title = '查看图片自动标注任务'
          this.inputType = true
        }
        if (num == 2) {
          this.title = '修改图片自动标注任务'
        }
        this.$axios(this.api.original.dyygmodelGetById + row.id, {}, 'get').then((res) => {
          if (res.status) {
            this.inputForm = res.data
            this.$set(this.inputForm, 'imgList', res.data.modelResources)
            this.dialogVisible = true
            this.$nextTick(() => {
              this.$refs['resume-upload'].setOldFile(this.inputForm.imgList)
            })
            if (this.inputForm.startUpTime && this.toDateSize(new Date(), this.inputForm.startUpTime)) {
              this.inputType = true
            }
          }
        })
      } else {
        this.title = '新增图片自动标注任务'
        this.dialogVisible = true
      }
    },

    //时间大小比较
    toDateSize(date1, date2) {
      date1 = new Date(date1).getTime()
      date2 = new Date(date2).getTime()
      if (date1 > date2) {
        return true
      } else {
        return false
      }
    },

    getUpData(data) {
      this.inputForm.imgList = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          this.$set(item.upDate, 'inputResource', item.upDate.netUrl)
          return item.upDate
        } else {
          return item
        }
      })
    },

    deleteFile(row, index) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.inputForm.imgList.splice(index, 1)
        this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
      }).catch(() => {
      })
    },

    sureData() {
      let setData = JSON.parse(JSON.stringify(this.inputForm))
      this.$delete(setData, 'imgList')
      let modelResourceList = this.inputForm.imgList.map(item => {
        return item.inputResource
      })
      this.$refs.inputForm.validate((valid) => {
        if (valid) {
          if (this.inputForm.id) {
            if (this.toDateSize(new Date(), this.inputForm.startUpTime) || inputForm.jobState == 1) {
              this.$message.error('任务已启动，不支持修改')
              return
            }
            this.$axios(this.api.original.dyygmodelUpdateById, {
              ...setData,
              modelResourceList,
            }, 'put').then((res) => {
              if (res.status) {
                this.$message.success('修改成功')
                this.$emit('refreshList')
                this.handleClose()
              } else {
                this.$message.error(res.msg);
              }
            })
          } else {
            if (this.inputForm.startUpTime && this.toDateSize(new Date(), this.inputForm.startUpTime)) {
              this.$message.error('定时启动时间必须大于当前时间')
              return
            }
            this.$axios(this.api.original.dyygmodelSave, {
              ...setData,
              modelResourceList,
            }, 'post').then((res) => {
              if (res.status) {
                this.$message.success('提交成功')
                this.$emit('refreshList')
                this.handleClose()
              } else {
                this.$message.error(res.msg);
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    handleClose() {
      if (this.inputForm.imgList.length) {
        this.inputForm.imgList.forEach(item => {
          this.$refs["resume-upload"].deleteFile(item.fileID ? item.fileID : item.id, '', 1)
        })
        this.inputForm.imgList = []
      }
      this.$refs.inputForm.resetFields()
      this.inputForm = {
        jobName: '',
        modelId: '',
        startUpTime: '',
        remake: '',

        startTime: '',
        endTime: '',
        jobType: '0',
        imgList: [],
      }
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>

</style>